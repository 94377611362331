import styles from './Contact.module.scss';
import Menu from '../menu/Menu';

function Contact() {
  return (
    <div>
      <Menu />  
    </div>
  );
}

export default Contact;