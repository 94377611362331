import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Cookbook() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates loading

  useEffect(() => {
    const token = localStorage.getItem('authToken'); // Retrieve the token from storage

    fetch('https://worldwidecookbook.com/backend/validateToken.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .then(data => {
      if (data.message === 'Success') {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate('/');
      }
    })
    .catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
      setIsAuthenticated(false);
      navigate('/');
    });
  }, [navigate]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // or any other loading indicator
  }

  if (!isAuthenticated) {
    // You can render nothing or a redirect message
    return null;
  }

  return (
    <div>
      My Cookbook
    </div>
  );
}

export default Cookbook;