import styles from './Home.module.scss';
import Menu from '../menu/Menu';

function Home() {
  return (
    <div>
      <Menu />
      <div>
      <img src="./images/wwcb.png" alt="World Wide Cookbook Logo" className={styles.logo} />
      </div>
    </div>
  );
}

export default Home;