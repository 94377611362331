import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const RecipeForm = () => {
    const navigate = useNavigate();
    const [ingredients, setIngredients] = useState([]);
    const [ingredientName, setIngredientName] = useState('');
    const [ingredientAmount, setIngredientAmount] = useState('');
    const [servings, setServings] = useState('');
    const [instructions, setInstructions] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('authToken')) {
            navigate('/login');
        }
    }, [navigate]);

    const handleAddIngredient = () => {
        if (ingredientName && ingredientAmount) {
            setIngredients([...ingredients, { name: ingredientName, amount: ingredientAmount }]);
            setIngredientName('');
            setIngredientAmount('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const authToken = localStorage.getItem('authToken');
        const recipeData = {
            ingredients,
            servings,
            instructions,
            description
        };

        try {
            await axios.post('/backend/api/recipe', recipeData, {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            });
            alert('Recipe submitted successfully!');
        } catch (error) {
            console.error('Error submitting recipe:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <input
                    type="text"
                    value={ingredientName}
                    onChange={(e) => setIngredientName(e.target.value)}
                    placeholder="Ingredient Name"
                />
                <input
                    type="text"
                    value={ingredientAmount}
                    onChange={(e) => setIngredientAmount(e.target.value)}
                    placeholder="Amount"
                />
                <button type="button" onClick={handleAddIngredient}>Add Ingredient</button>
            </div>

            <ul>
                {ingredients.map((ingredient, index) => (
                    <li key={index}>{ingredient.name}: {ingredient.amount}</li>
                ))}
            </ul>

            <div>
                <input
                    type="number"
                    value={servings}
                    onChange={(e) => setServings(e.target.value)}
                    placeholder="Number of Servings"
                />
            </div>

            <div>
                <textarea
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                    placeholder="Instructions"
                />
            </div>

            <div>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
            </div>

            <button type="submit">Submit Recipe</button>
        </form>
    );
};

export default RecipeForm;