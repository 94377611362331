import { Link } from 'react-router-dom';

const Menu = () => {
    return (
        <nav>
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/recipes">Recipes</Link></li>
                <li><Link to="/login">Login</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/contact">Contact</Link></li>
                <li><Link to="/my/cookbook">My Cookbook</Link></li>
                <li><Link to="/my/cookbook/create/recipe">Create Recipe</Link></li>
            </ul>
        </nav>
    );
};

export default Menu;
