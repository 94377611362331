import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/home/Home";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Recipes from "./components/recipes/Recipes";
import Login from "./components/login/Login";
import Cookbook from "./components/my/cookbook/cookbook/Cookbook";
import RecipeForm from "./components/my/cookbook/create/recipe/Recipe";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/my/cookbook" element={<Cookbook />} />
        <Route path="/my/cookbook/create/recipe" element={<RecipeForm />} />
      </Routes>
    </Router>
  );
}

export default App;
