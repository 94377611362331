import styles from './About.module.scss';
import Menu from '../menu/Menu';

function About() {
  return (
    <div>
      <Menu />
    </div>
  );
}

export default About;