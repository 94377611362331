import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';
import Menu from '../menu/Menu';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleLogin = async (event) => {
      event.preventDefault();

      try {
        const response = await fetch('https://worldwidecookbook.com/backend/ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'login',
                email: email,
                password: password
            }),
        });

          const data = await response.json();

          if (data.message === 'Success') {
              localStorage.setItem('authToken', data.token); // Assuming the token is in data.token
              navigate('/my/cookbook');
            } else {
              setError('Login failed. Please check your credentials.');
          }
      } catch (error) {
          setError('An error occurred. Please try again later.');
      }
  };

  return (
      <div>
        <Menu />
          <form onSubmit={handleLogin}>
              <div>
                  <label>Email:</label>
                  <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                  />
              </div>
              <div>
                  <label>Password:</label>
                  <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
              </div>
              <button type="submit">Login</button>
          </form>
          {error && <p>{error}</p>}
      </div>
  );
}

export default Login;