import styles from './Recipes.module.scss';
import Menu from '../menu/Menu';

function Recipes() {
  return (
    <div>
      <div>
        <Menu />
      </div>
Recipes
    </div>
  );
}

export default Recipes;